/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-15",
    versionChannel: "nightly",
    buildDate: "2024-08-15T00:06:05.597Z",
    commitHash: "0bd16b202cbe531931e44a26907e53bd68871e2d",
};
